import React, {
  memo,
} from "react";

// import Marquee from "react-fast-marquee";
// import { NavLink } from "react-router-dom";

// In house
// import {getSponsors} from '../../../services/assetService';

const FooterPage = () => {

  // const [sponsors, setSponsors] = useState(false)
  // useEffect(() => {
  //   const fetchData = async () => {
  //      const {data} = await getSponsors();
  //      setSponsors(data);
  //   }
  //   fetchData();
  // }, []);

  // const tierPick = tier => tier === 1 ? '💎' : (tier === 2) ? '🥇' : (tier === 3) ? '🥈' : '🥉'
  // const sponsorURL = (name, link) => link === '' ? name : <a className='text-warning' href={link} target='_blank' rel="noreferrer"><u>{name}</u></a>

  return (
    <>
      <div 
        style={{
          position: "fixed",
          bottom: 0,
          right: 0,
          fontSize: "8px",
          width: "100%",
          background: "none",
          color: "lightgray",
          marginRight: "10px",
          textAlign: "center",
        }}
      >
        {/* {sponsors ? 
          <Marquee
            delay={2}
            pauseOnHover={true}
            speed={50}
          >
            <h5 className='mx-2 p-1 bg-dark'>Thanks to our <span><NavLink className={"underline p-0 m-0 text-primary"} to={`/sponsor`}><u className='bg-dark  p-1'>Sponsors!</u></NavLink></span></h5>
            {sponsors.map(d => <h5 className='mx-2 flex-row bg-dark  p-1' key={d.name}>{sponsorURL(d.name, d.link)}{tierPick(d.tier)}</h5>)}
            <h5 className='mr-3'></h5>
          </Marquee>  : 
          '...'
        } */}
        <p className="p-0 m-0">© 2024 Trend Edge App | V. 4.2.091324</p>
      </div>
    </>
  );
};

export default memo(FooterPage);