// Out of house
import React, { lazy, useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";
import { ThemeProvider } from "@emotion/react";

// In house
import SignOut from "./components/unique/userAccess/signOut";
import ProtectedRoute from "./components/common/protectedRoute";
import Loading from "./components/common/loading/loading";
import FullscreenOverlay from "./components/common/overlay";


import "./App.css";
import ServeToDash from "./components/common/serveToDash";
import FooterPage from "./components/unique/dashboard/footer";
import Dashboard from "./components/unique/dashboard/dashboard";

// Context
import { GlobalContext } from "./store/globalContext";
import { useLocation } from "react-router-dom";

// Lazy loading routes
const About = lazy(() => import("./components/unique/about/about"));
const AdminDash = lazy(() => import("./components/unique/adminDash/adminDash"));

const IndustryStatistics = lazy(() =>
  import("./components/unique/industryStatistics/wrappedIndustryStatistics")
);
const Install = lazy(() => import("./components/unique/install/install"));
const Webinars = lazy(() => import("./components/unique/webinars/webinars"));

const Institutions = lazy(() =>
  import("./components/unique/institutions/institutions")
);

const ReleaseNotes = lazy(() =>
  import("./components/unique/releaseNotes/releaseNotes")
);
const NotFound = lazy(() => import("./components/unique/notFound"));
const Indicators = lazy(() =>
  import("./components/unique/indicators/wrappedIndicatorsPage")
);
const Screener = lazy(() =>
  import("./components/unique/screener/wrappedScreenerPage")
);
const UserSettings = lazy(() =>
  import("./components/unique/userSettings/wrappedUserSettings")
);
const Asset = lazy(() =>
  import("./components/unique/singleAsset/wrappedAssetPage")
);
const HookedAccess = lazy(() =>
  import("./components/unique/userAccess/hookedAccess")
);
const Watchlist = lazy(() => import("./components/unique/watchlist/watchlist"));
const WeeklyInsights = lazy(() =>
  import("./components/unique/weeklyInsights/weeklyInsights")
);

// const SponsorPage = lazy(() => import('./components/unique/sponsor/sponsor'));
// const SponsorSuccessPage = lazy(() => import('./components/unique/sponsor/sponsorSuccess'));

const theme = createTheme();

const App = () => {
  const { user, allAssetNames } = useContext(GlobalContext);

  if (!allAssetNames.length || !user)
    return <FullscreenOverlay isLoading={true} />;
  else
    return (
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={theme}>
          <ThemeProvider theme={theme}>
            <Dashboard allAssetNames={allAssetNames} />
            <React.Suspense
              fallback={
                <ServeToDash className="mx-0 px-0 bg-primary" pageBreak={false}>
                  <Loading type={"spin"} />
                </ServeToDash>
              }
            >
              <div
                className="container-fluid"
                style={{ margin: 0, padding: 0, background: "#6c757d" }}
              >
                <Routes>
                  <Route path="/about" element={<About />} />
                  <Route element={<ProtectedRoute adminTrue={true} />}>
                    <Route path="/admin" element={<AdminDash />} />
                  </Route>

                  <Route path="/indicators" element={<Indicators />} />
                  <Route
                    path="/industryStats"
                    element={<IndustryStatistics />}
                  />
                  <Route path="/install" element={<Install />} />
                  <Route path="/webinars" element={<Webinars />} />
                  <Route path="/institutions" element={<Institutions />} />
                  <Route path="/releaseNotes" element={<ReleaseNotes />} />
                  <Route path="/screener" element={<Screener />} />
                  <Route
                    path="/weeklyInsights"
                    element={<WeeklyInsights />}
                  />

                  <Route path="/asset/:name" element={<Asset />} />

                  <Route path="/Sign In" element={<HookedAccess/>} />
                  <Route path="/Sign Up" element={<HookedAccess/>} />

                  <Route path="/Sign Out" element={<SignOut />} />

                  <Route element={<ProtectedRoute/>}>
                    <Route path="/settings" element={<UserSettings />} />
                  </Route>

                  <Route element={<ProtectedRoute/>}>
                    <Route path="/watchlist" element={<Watchlist />} />
                  </Route>

                  <Route path="/not-found" element={<NotFound />} />

                  <Route path="/" element={<Navigate to="/screener" replace />} />

                  <Route path="/dash" element={<Navigate to="/screener" replace />} />

                  <Route path="*" element={<Navigate to="/not-found" replace />} />

                  {/* <Route path="/sponsor" element={<SponsorPage/>}/>
                  <Route path="/sponsorSuccess/:thankYou" element={<SponsorSuccessPage/>}/> */}
                </Routes>
              </div>
              <FooterPage />
            </React.Suspense>
          </ThemeProvider>
        </MuiThemeProvider>
      </StyledEngineProvider>
    );
};

export default function AppWrapper(props) {
  const location = useLocation();

  return <App {...props} location={location} />;
}
